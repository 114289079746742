import React, { useContext } from "react";
import {Link} from "react-router-dom";
import AuthContext from '../../../store/authContext';
import './header.scss';
import logo from '../../../assets/capital-power-clean-logo.jpg'

export default function Header() {
    const authCtx = useContext(AuthContext);
    function handleLogout() {
        authCtx.logout();
    }
    return (
        <nav className="navbar navbar-expand-lg header">
            <div className="container-fluid">
                <Link className="navbar-brand">
                    <img src={logo} alt="logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Stock Management </Link>
                        <ul className="dropdown-menu">
                            {/* <li>
                            <Link to="stock-location" className="dropdown-item">Stock Location</Link>
                            </li> */}
                            <li>
                            <Link to="stock-take" className="dropdown-item">Stock Take</Link>
                            </li>
                        </ul>
                        </li>
                        {/* <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Menu 2 </Link>
                        <ul className="dropdown-menu">
                            <li>
                            <Link className="dropdown-item">Item 1</Link>
                            </li>
                            <li>
                            <Link className="dropdown-item">Item 2</Link>
                            </li>
                        </ul>
                        </li>
                        <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Menu 3 </Link>
                        <ul className="dropdown-menu">
                            <li>
                            <Link className="dropdown-item">Item 1</Link>
                            </li>
                            <li>
                            <Link className="dropdown-item">Item 3</Link>
                            </li>
                        </ul>
                        </li> */}
                        {/* <li className="nav-item"><Link  className="nav-link" >Menu 2</Link></li>
                        <li className="nav-item"><Link className="nav-link" >Menu 3</Link></li> */}
                        <li className="nav-item"><Link className="nav-link" to="reports">Reports</Link></li>
                    </ul>
                    <div className="d-flex me-4" role="search">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item dropdown me-4">
                        <Link className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"> System Maintenance </Link>
                        <ul className="dropdown-menu">
                            <li>
                            <Link to="location-management" className="dropdown-item">Location Management</Link>
                            </li>
                            <li>
                                <Link to="user-management" className="dropdown-item">User Management</Link>
                            </li>
                        </ul>
                        </li>
                        <li className="nav-item">
                            <Link to="/" className="nav-link" onClick={handleLogout} >Logout</Link>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end user-detail"> Logged in as &nbsp;{authCtx.user.userName} </div>
        </nav>
    )
}