import React, { useContext, useEffect, useState } from "react";

import { ShimmerText } from "react-shimmer-effects";

import { getLocations, saveNewLocation, deleteLocation } from "../../services/dataService";
import Pagination from "../common/pagination/index";



import './locationManagement.scss';

export default function LocationManagment() {
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

    useEffect(()=> {
        getLocationsData();
    }, [])
    const getLocationsData = async (currentPageNo) => {
        try {
            setIsLoading(true);
            setLocations([]);
            const result = await getLocations(currentPageNo);
            clearData();
            setLocations(result.data);
            setPageCount(result.totalPages);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };
    const saveNewLocationData = async () => {
        try {
            const result = await saveNewLocation(selectedLocation);
            getLocationsData();
        } catch (error) {
        }
    };

    const handleSelectLocation = (location) => {
        setSelectedLocation(location.locationName);
        setShowDelete(true);
      };

      const paginatedData = (pageNo) => {
        setCurrentPageNo(pageNo + 1);
        getLocationsData(pageNo + 1);
        console.log("current requested page", pageNo);
    }

    const handleDeleteLocation = async() => {
        try {
            const foundLocation = locations.find(location => location.locationName === selectedLocation);
            const result = await deleteLocation(foundLocation.locationId);
            getLocationsData();
        } catch (error) {
        }
    };
    const clearData = () => {
        setSelectedLocation('');
        setShowDelete(false);
    }
    const handleChange = (value) => {
        setSelectedLocation(value);
        setShowDelete(false);
    }
    return (
        <div className="location-main">
            <div className="locations-list">
                <h5 className="heading">Locations</h5>
                {isLoading && 
                    <ShimmerText line={5} gap={10} />
                }
                {!isLoading &&locations.length === 0 && 
                    <div>No Data Found</div>
                }
                {locations.map((location, index) => (
                <div className="list-content" key={index} onClick={() => handleSelectLocation(location)}>
                    {location.locationName}
                </div>
                ))}
                <div className="mt-3 d-flex justify-content-around">
                    <Pagination itemsPerPage={10} items={locations} totalPages={pageCount}  onNavigation={paginatedData}/>
            </div>
            </div>

            <div className="location-editor">
                <div className="row">
                    <div className="col-4 action-buttons">
                        {showDelete && 
                            <span className="action-del" onClick={handleDeleteLocation}>Delete</span>
                        }
                        <h5 className="heading">Location</h5>
                        <input 
                        type="text" 
                        value={selectedLocation}
                        className="form-control"
                        onChange={(e) => handleChange(e.target.value)} 
                        />
                        <div className="text-end">
                        <button type="button" className="btn btn-sm" onClick={clearData}>Cancel</button>
                        <button type="button" className="btn btn-sm" onClick={saveNewLocationData} >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}