import api from '../services/interceptor';
import global from './globalService';



export const getAllUsers = async () => {
    try {
        const response = await api.get(global.users);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveNewUser = async (email, username, password) => {
    try {
        const response = await api.post(global.register, {email: email, username: username, password: password});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (user) => {
    try {
        const response = await api.delete(global.deleteUser, {
            data: user,
            headers: {
                'Content-Type': 'application/json'
              }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getLocations = async (pageNo = 1, PageSize = 15) => {
    try {
        const response = await api.get(global.locations, {
            params: {
                PageNumber: pageNo,
                PageSize: PageSize
            }
          });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllLocations = async () => {
    try {
        const response = await api.get(global.allLocations);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveNewLocation = async (locationName) => {
    try {
        const response = await api.post(global.locations, {locationName: locationName});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateStockLocationRequest = async (stockData, value, stockId) => {
    try {
        let response;
        if (stockData.stockTakeId) {
             response = await api.put(global.stockTakes + '/' + stockData.stockTakeId, {stockTakeId: stockData.stockTakeId,locationId: stockData.locationId,freeStock:value,currentStock:stockData.currentStock, stockId: stockData.stockId  });
        } else {
             response = await api.post(global.stockTakes, {locationId: stockData.locationId,currentStock:stockData.currentStock === 0 || stockData.currentStock === '0' ? '-99': stockData.currentStock, freeStock:value, stockId: stockId  });
        }
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateStockRequest = async (stockData, value, stockId) => {
    console.log("stockData.stockTakeId", stockData.stockTakeId);
    try {
        let response;
        if (stockData.stockTakeId) {
             response = await api.put(global.stockTakes + '/' + stockData.stockTakeId, {stockTakeId: stockData.stockTakeId,locationId: stockData.locationId,freeStock:stockData.freeStock,currentStock:value, stockId: stockData.stockId  });
        } else {
             response = await api.post(global.stockTakes, {locationId: stockData.locationId,freeStock:stockData.freeStock,currentStock:value, stockId: stockId  });
        }
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteLocation = async (locationId) => {
    try {
        const response = await api.delete(global.locations + '/' + locationId);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getStockLocations = async (pageNo = 1, searchVal = "", orderType = "desc", orderByKey = "ProductCode", PageSize = 10,) => {
    try {
        const response = await api.get(global.stockLocations, {
            params: {
                PageNumber: pageNo,
                PageSize: PageSize,
                text: searchVal,
                OrderBy: orderByKey,
                OrderType: orderType


            }
          });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getStockTakes = async () => {
    try {
        const response = await api.get(global.stockLocations);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteStocks = async (ids) => {
    try {
        const response = await api.delete(global.deleteStocks, {
            data: ids,
            headers: {
                'Content-Type': 'application/json'
              }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const exportDataToSage = async () => {
    try {
        const response = await api.get(global.exportSageData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getReports = async (pageNo = 1, PageSize = 15) => {
    try {
        const response = await api.get(global.reports, {
            params: {
                PageNumber: pageNo,
                PageSize: PageSize
            }
          });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteReport = async (reportId) => {
    try {
        const response = await api.delete(global.reports + '/' + reportId);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveReport = async (reportId) => {
    try {
       const  headers  = {'Content-Type': 'blob'}
        const response = await api.get(global.saveReport + '/' + reportId,{ header: headers, responseType: "blob"});
        return response.data;
    } catch (error) {
        throw error;
    }
};