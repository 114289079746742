import React, { useState } from "react";
const AuthContext = React.createContext({
    token: '',
    user: '',
    isLoggedIn: false,
    currentPageHeading: '',
    currentUserType: '',
    pageHeading: (heading) => { },
    currentUser: (heading) => { },
    login: (token) => { },
    logout: () => { },
});

export const AuthContextProvider = (props) => {
    const initialToken = localStorage.getItem('token');
    // const initialUser =  JSON.parse(localStorage.getItem('user'));
    const [token, setToken] = useState(initialToken);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const userIsLoggedIn = !!token;
    const loginHandler = (token) => {
        setToken(token);
        localStorage.setItem('token', token);
    };
    const logoutHanlder = () => {
        setToken(null);
        localStorage.clear();
        window.location.href = '/login';
    }
    const setCurrentUser = (user) => {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
    }
    const contextValue = {
        token: token,
        user: user,
        isLoggedIn: userIsLoggedIn,
        currentUser: setCurrentUser,
        login: loginHandler,
        logout: logoutHanlder
    }
    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
};

export default AuthContext;