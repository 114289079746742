import React, { useState, useContext } from 'react';
import { useNavigate  } from "react-router-dom";

import AuthService from '../../../services/authService';
import AuthContext from '../../../store/authContext';
import logo from '../../../assets/capital-power-clean-logo.jpg'
import asset_loader from '../../../assets/loader.svg';

import './login.scss';
import auth from '../../../services/authService';

export default function Login() {
    const authCtx = useContext(AuthContext);
    let navigate  = useNavigate();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [errorLogin, setErrorLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Sorry, we couldn’t find an account with provided credentials.');
    const handleLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        AuthService.authToken(username, password)
            .then(res => {
                setIsLoading(false);
                authCtx.login(res.data.token);
                let userObj = {
                    userName: username,
                    email: res.data.email
                }
                authCtx.currentUser(userObj);
                navigate('/stock-take')
            })
            .catch(err => {
                setIsLoading(false);
                if (err.response) {
                    // setErrorMessage(err.response.data.detail);
                    setErrorLogin(true);
                } else {
                    navigate('/');
                }
            })
    }
    return (
        <div className='login-main'>
            <div className="login-bg"></div>
            <div className="login-box">
                {/* <h2 className="heading-login-box"></h2> */}
                <div className="login-logo">
                    <img src={logo} alt="logo" />
                </div>
                <form onSubmit={handleLogin}>
                    <div className="user-box">
                        <input className="user-box-input" type="text" required onChange={e => setUsername(e.target.value)} />
                        <label className="user-box-label">Username</label>
                    </div>
                    <div className="user-box">
                        <input className="user-box-input" type="password" required onChange={e => setPassword(e.target.value)} />
                        <label className="user-box-label">Password</label>
                    </div>
                    {errorLogin &&
                        <div className="error-message text-start fw-bold">
                            <div>{errorMessage}</div>
                        </div>
                    }
                    <button to="stock-take" className="login-box-button btn" disabled={!(username && password) ? true : false}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {isLoading ?
                            <div className="loader">
                                <img className="btn-loader" src={asset_loader} alt="Loader" />
                            </div>
                            :
                            <>Login</>
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}