import React, { useContext, useEffect, useState } from "react";

import { ShimmerText } from "react-shimmer-effects";

import { getReports, saveReport, deleteReport } from "../../services/dataService";
import Pagination from "../common/pagination/index";
import asset_loader from '../../assets/loader.svg';



import './reports.scss';

export default function Reports() {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReport, setselectedReport] = useState('');
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

    useEffect(()=> {
        getReportsData();
    }, [])
    const getReportsData = async (currentPageNo) => {
        try {
            setIsLoading(true);
            setReports([]);
            const result = await getReports(currentPageNo);
            clearData();
            setReports(result.data);
            setPageCount(result.totalPages);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };
    const downloadReport = async () => {
        try {
            setIsDownloading(true);
            const foundLocation = reports.find(location => location.reportName === selectedReport);
            const result = await saveReport(foundLocation.reportId);
            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${selectedReport}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
        } catch (error) {
            setIsDownloading(false);
        }
    };

    const handleSelectLocation = (location) => {
        setselectedReport(location.reportName);
        setShowDelete(true);
      };

      const paginatedData = (pageNo) => {
        setCurrentPageNo(pageNo + 1);
        getReportsData(pageNo + 1);
        console.log("current requested page", pageNo);
    }

    const handleDeleteReport = async() => {
        try {
            const foundLocation = reports.find(location => location.reportName === selectedReport);
            const result = await deleteReport(foundLocation.reportId);
            getReportsData();
        } catch (error) {
        }
    };
    const clearData = () => {
        setselectedReport('');
        setShowDelete(false);
    }
    const handleChange = (value) => {
        setselectedReport(value);
        setShowDelete(false);
    }
    return (
        <div className="reports-main">
            <div className="reports-list">
                <h5 className="heading">Reports</h5>
                {isLoading && 
                    <ShimmerText line={5} gap={10} />
                }
                {!isLoading &&reports.length === 0 && 
                    <div>No Data Found</div>
                }
                {reports.map((report, index) => (
                <div className="list-content" key={index} onClick={() => handleSelectLocation(report)}>
                    {report.reportName}
                </div>
                ))}
                <div className="mt-3 d-flex justify-content-around">
                    <Pagination itemsPerPage={10} items={reports} totalPages={pageCount}  onNavigation={paginatedData}/>
            </div>
            </div>

            <div className="location-editor">
                <div className="row">
                    <div className="col-4 action-buttons">
                        <h5 className="heading">Report</h5>
                        {/* <input 
                        type="text" 
                        value={selectedReport}
                        className="form-control"
                        onChange={(e) => handleChange(e.target.value)} 
                        /> */}
                         {/* {showDelete && 
                            <span className="action-del" onClick={handleDeleteReport}>Delete</span>
                        } */}
                        {selectedReport !== "" && 
                        <>
                            <h4>{selectedReport}</h4>
                            <div className="text-end">
                            <button type="button" className="btn btn-sm" onClick={clearData}>Cancel</button>
                            {isDownloading ? (
                            <button className="btn btn-sm text-center" style={{background: "#ffffff"}}>
                                <img src={asset_loader} alt="loader" style={{width: "30px"}} />
                            </button>
                            ): (
                            <button type="button" className="btn btn-sm" onClick={downloadReport} disabled={selectedReport === ""} >Download</button>
                            )}
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}