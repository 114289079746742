import './App.scss';
import React, {useContext, useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AuthContext from './store/authContext';
import Login from './components/auth/login/index';
import Header from './components/common/header';
import StockManagment from './components/stockLocation/index';
import StockTake from './components/stockTake/index';
import LocationManagment from './components/locationManagement/index';
import UserManagment from './components/userManagement';
import Reports from './components/reports/index';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  const authCtx = useContext(AuthContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (screenWidth < 1080) {
    return (
      <div className="overlay">
        Please increase your screen resolution to 1080px to view this website.
      </div>
    );
  } else {
  
  return (
    <div className="App">
      <ToastContainer />
      <Router>
      <div>
      {authCtx.isLoggedIn && (
        <Header></Header>
      )}
        {!authCtx.isLoggedIn ? (
          <Routes>
          <Route path='/login' element={<Login/>} />
          <Route path="*" element={<Login />} />

          </Routes>
          ) : (
          <Routes>
            <Route path='/stock-take' element={<StockTake/>} />
            <Route path='/reports' element={<Reports/>} />
            <Route path='/location-management' element={<LocationManagment/>} />
            <Route path='/user-management' element={<UserManagment/>} />
            <Route path="*" element={<StockTake />} />
          </Routes>
          )}
      </div>
    </Router>
    </div>
  );
}
}

export default App;
