import React, { useContext, useEffect, useState } from "react";
import {Link} from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import { toast } from 'react-toastify';
import AuthContext from '../../store/authContext';

import { getAllLocations, getStockLocations, updateStockRequest, deleteStocks, exportDataToSage } from "../../services/dataService";

import Pagination from "../common/pagination/index";

import sortIcon from '../../assets/sort.png';
import deleteIcon from '../../assets/delete.svg';
import asset_loader from '../../assets/loader.svg';



import './stockTake.scss';

export default function StockManagment() {
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [stockTakeData, setStockTakeData] = useState([]);
    const [locationsData, setLocationsData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [totalUniqueStocks, setTotalUniqueStocks] = useState("");
    const [pageCount, setPageCount] = useState(null);
    const [orderType, setOrderType] = useState("desc");
    const [orderByKey, setOrderByKey] = useState("ProductCode");
    const [checkedItems, setCheckedItems] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [searchLoad,setSearchLoad] = useState(false);
    const [locationsWithTakes, setlocationsWithTakes] = useState([]);
    const [recordDeleteArray, setRecordDeleteArray] = useState([]);
    const [checkedStates, setCheckedStates] = useState([]);
    const [exportDataLoader, setExportDataLoader] = useState(false);
    const [, forceUpdate] = useState();
    const [sortConfig, setSortConfig] = useState({ key: "ProductCode", direction: "desc" });
    const [previousValueOfInput,setPreviousValueOfInput] = useState(0);
    const [columnWidths, setColumnWidths] = useState({
        column1: {fixedColumnWidth: 15, left: 20},
        column2: {fixedColumnWidth: 15, left: 15},
        column3: {fixedColumnWidth: 30, left: 30},
        column4: {fixedColumnWidth: 15, left: 60},
        locationDropWidth: "95%",
        scrollableColumnWidth: {scrollableColumnWidth: 15, left: 75},
        columnLast: {fixedColumnWidth: 10, right: 0},
      });

    useEffect(()=> {
        // getLocationsData();
    }, [currentPageNo,locationsData.length])

    useEffect(() => {
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [timeoutId]);

    useEffect (() => {
        getLocationsData();
    }, [locationsData.length]);

    const getLocationsData = async () => {
        try {
            const arrayData = []
            const result = await getAllLocations();
            setLocationsData(result);
            setCheckedStates(new Array(1).fill(true));
            // setCheckedStates(new Array(locationsData.length).fill(true));
           await result.map((loc, index) => {
                    arrayData.push( {
                        locationName: loc.locationName,
                        locationId : loc.locationId,
                        currentStock: 0,
                        currentStockFromApi: -1,
                        freeStock: 0,
                        stockId: null,
                        stockTakeId: null,
                        isShow: index ===0? true: false
                    })
            })
            setlocationsWithTakes(arrayData);
            getStockLocationsData(currentPageNo, searchText, sortConfig.direction, sortConfig.key, arrayData);

        } catch (error) {
        }
    };

    const getStockLocationsData = async (pageNo, searchVal, sortType, sortBy, stockWithTakes) => {
        try {
            const result = await getStockLocations(pageNo, searchVal, sortType, sortBy);
            setPageCount(result.totalPages);
            setSearchLoad(false);
            setTotalUniqueStocks(result.totatUniqueStocks);
            let data = result.data;
            for (const entry of data) {
                entry.locationsWithTakes = stockWithTakes ? JSON.parse(JSON.stringify(stockWithTakes))  : JSON.parse(JSON.stringify(locationsWithTakes));
            
                if (entry.stockTakes?.length > 0) {
                    for (const stockTake of entry.stockTakes) {
                        const locInd = entry.locationsWithTakes.findIndex(m => m.locationId === stockTake.locationId);
            
                        if (locInd >= 0) {
                            entry.locationsWithTakes[locInd].currentStock = parseInt(stockTake.currentStock) === -99? 0 : stockTake.currentStock;
                            entry.locationsWithTakes[locInd].currentStockFromApi = parseInt(stockTake.currentStock);
                            entry.locationsWithTakes[locInd].freeStock = stockTake.freeStock;
                            entry.locationsWithTakes[locInd].stockId = stockTake.stockId;
                            entry.locationsWithTakes[locInd].stockTakeId = stockTake.stockTakeId;
                        }
                    }
                }
                entry.totalCount = await getTotalCountOnLoad(entry.locationsWithTakes);
                entry.diffrenceFromTotal = getTotalDigffrence(entry.totalCount, entry.freeStock);
                entry.isLoader = false;
            }
            let changeData = stockTakeData;
            changeData = data;
            setStockTakeData(changeData);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleSearch = (value = "") => {
        setSearchLoad(true);
        // const newValue = value;
        // if (!newValue) {
            // setSearchText("");
            // setCurrentPageNo(1);
            // setIsLoading(true);
            // getStockLocationsData(1, "", sortConfig.direction, sortConfig.key,);
        // } else {
            // setSearchText(value);
            setCurrentPageNo(1);
            // if (timeoutId) clearTimeout(timeoutId);
            // const newTimeoutId = setTimeout(() => {
                setIsLoading(true);
                // getStockLocationsData(1, searchText, sortConfig.direction, sortConfig.key);
                setSortConfig({ key: 'ProductCode', direction: 'asc' });
                getStockLocationsData(1, searchText, "asc", "ProductCode");
            // }, 2000);
            // setTimeoutId(newTimeoutId);
        // }
    }

    const paginatedData = (pageNo) => {
        setIsLoading(true);
        setCurrentPageNo(pageNo + 1);
        getStockLocationsData(pageNo + 1, searchText, orderType, orderByKey);
    }
    const handleDropdownClick = (e) => {
        e.stopPropagation();
    }

    const handleItemChange = (id) => {
        let dataToDelete = [...recordDeleteArray]
        setCheckedItems(prev => ({ ...prev, [id]: !prev[id] }));
        if (!dataToDelete.includes(id)) {
            dataToDelete.push(id);
          } else {
            // let idToRemove = 2;
            let index = dataToDelete.findIndex(record => record === id);

            if (index !== -1) {
                dataToDelete.splice(index, 1);
            }
          }
        setRecordDeleteArray(dataToDelete);
    };

    const handleSelectAllChange = () => {
        let deleteArray = [...recordDeleteArray];
        if (selectAll) {
          setCheckedItems({});
          setRecordDeleteArray([])
        } else {
          const allChecked = stockTakeData.reduce((acc, item) => {
            acc[item.stockId] = true;
            deleteArray.push(item.stockId);
            return acc;
          }, {});
          setRecordDeleteArray(deleteArray);
          setCheckedItems(allChecked);
        }
        setSelectAll(!selectAll);
      };

    //   const handleChange = async(value, locationData, stockId, parentindex, childIndex) =>  {
    //     if (value && (parseInt(value) !== locationData.currentStock)) {
    //         let currentDataToUpdateLoading = [...stockTakeData];
    //         currentDataToUpdateLoading[parentindex].isLoader = true;
    //         setStockTakeData(currentDataToUpdateLoading);
    //         try {
    //             const result = await updateStockRequest(locationData, value, stockId);
    //             let currentDataToUpdate = [...stockTakeData];
    //             currentDataToUpdate[parentindex].locationsWithTakes[childIndex].currentStock = parseInt(value);
    //             currentDataToUpdate[parentindex].isLoader = false;
    //             currentDataToUpdate[parentindex].totalCount =  getTotalCount(currentDataToUpdate[parentindex].locationsWithTakes);
    //             setStockTakeData(currentDataToUpdate);
    //         } catch(error) {
    //         }
    //     }
    // }

    const onFocusOut = async(value, locationData, stockId, parentindex, childIndex) =>  {
        if (value && (parseInt(value) !== locationData.currentStock)) {
            let currentDataToUpdateLoading = [...stockTakeData];
            currentDataToUpdateLoading[parentindex].isLoader = true;
            setStockTakeData(currentDataToUpdateLoading);
            try {
                const result = await updateStockRequest(locationData, value, stockId);
                let currentDataToUpdate = [...stockTakeData];
                // currentDataToUpdate[parentindex].locationsWithTakes[childIndex].currentStock = parseInt(value);
                currentDataToUpdate[parentindex].isLoader = false;
                // currentDataToUpdate[parentindex].totalCount =  getTotalCount(currentDataToUpdate[parentindex].locationsWithTakes);
                setStockTakeData(currentDataToUpdate);
                await getStockLocationsData(currentPageNo, searchText, sortConfig.direction, sortConfig.key);
            } catch(error) {
            }
        } else {
                let currentDataToUpdate = [...stockTakeData];
                currentDataToUpdate[parentindex].locationsWithTakes[childIndex].currentStock = previousValueOfInput;
                setStockTakeData(currentDataToUpdate);
        }
}

       async function handleChange(value, locationData, stockId, parentindex, childIndex) {
                let currentDataToUpdate = [...stockTakeData];
                currentDataToUpdate[parentindex].locationsWithTakes[childIndex].currentStock = value;
                currentDataToUpdate[parentindex].totalCount = await getTotalCount(currentDataToUpdate[parentindex].locationsWithTakes);
                currentDataToUpdate[parentindex].diffrenceFromTotal = getTotalDigffrence(currentDataToUpdate[parentindex].totalCount, currentDataToUpdate[parentindex].freeStock);
                setStockTakeData(currentDataToUpdate);
        }

        const handleFocus = (value, locationData, stockId, parentindex, childIndex) => {
            let currentDataToUpdate = [...stockTakeData];
            setPreviousValueOfInput(currentDataToUpdate[parentindex].locationsWithTakes[childIndex].currentStock);
            currentDataToUpdate[parentindex].locationsWithTakes[childIndex].currentStock = '';
            setStockTakeData(currentDataToUpdate);
        };

    function handleChangeForLocationDrop(index, value, closeCase, locationId) {
        let count = 0;
        let indexesOfLocations = [...locationsWithTakes];
        let data = [...stockTakeData];
        const updatedCheckedStates = [...checkedStates];
        for ( let i = 0; i < data.length; i++ ) {
            if (closeCase){
                data[i].locationsWithTakes[index].isShow =  value;
                updatedCheckedStates[index] = value;
            } else {
                data[i].locationsWithTakes[index].isShow =  value.checked;
                updatedCheckedStates[index] = value.checked;
            }
        }
        locationsWithTakes.map((data, index) => {
            if(data.locationId === locationId) {
                indexesOfLocations[index].isShow = closeCase?value: value.checked;
            }
        })
        setlocationsWithTakes(indexesOfLocations);
        setCheckedStates(updatedCheckedStates);
        setStockTakeData(data);
        for(let i=0; i<indexesOfLocations.length; i++) {
            if(indexesOfLocations[i].isShow) {
                count ++;
            }
        }
        if (count <= 1) {
            setColumnWidths({
                ...columnWidths,
                column1: {fixedColumnWidth: 15, left: 20},
                column2: {fixedColumnWidth: 15, left: 15},
                column3: {fixedColumnWidth: 30, left: 30},
                column4: {fixedColumnWidth: 15, left: 60},
                locationDropWidth: "95%",
                scrollableColumnWidth: {scrollableColumnWidth: 15, left: 75},
              });
        }
        if (count === 2) {
            setColumnWidths({
                ...columnWidths,
                column1: {fixedColumnWidth: 15, left: 20},
                column2: {fixedColumnWidth: 15, left: 15},
                column3: {fixedColumnWidth: 25, left: 30},
                column4: {fixedColumnWidth: 15, left: 55},
                scrollableColumnWidth: {scrollableColumnWidth: 20, left: 70},
                locationDropWidth: "60%",
              });
        }
        if (count === 3) {
            setColumnWidths({
                ...columnWidths,
                column1: {fixedColumnWidth: 10, left: 20},
                column2: {fixedColumnWidth: 10, left: 10},
                column3: {fixedColumnWidth: 30, left: 20},
                column4: {fixedColumnWidth: 10, left: 50},
                scrollableColumnWidth: {scrollableColumnWidth: 30, left: 60},
                locationDropWidth: "300px",
              });
        }
        if (count >= 4) {
            setColumnWidths({
                ...columnWidths,
                column1: {fixedColumnWidth: 10, left: 20},
                column2: {fixedColumnWidth: 10, left: 10},
                column3: {fixedColumnWidth: 20, left: 20},
                column4: {fixedColumnWidth: 10, left: 40},
                scrollableColumnWidth: {scrollableColumnWidth: 40, left: 50},
                locationDropWidth: "300px",
              });
        }
    }

    function getTotalCountOnLoad (data) {
        let total = 0;
        let flag = 0;
        for(let i=0; i<data.length; i++) {
         if (data[i].currentStockFromApi>=0) {
             total = data[i].currentStock + total;
        } else {
            flag = flag + 1;
        }
    }
        if (flag === data.length) {
         total = 0;
         for(let i=0; i<data.length; i++) {
             total = parseInt(data[i].freeStock) + total;
             
            }
        }
        return total;
     }
     
    function getTotalCount (data) {
           let total  = 0;
           for(let i=0; i<data.length; i++) {
            if (data[i].currentStock !== "") {
                total = parseInt(data[i].currentStock) + total;
            } else {
                total = parseInt(previousValueOfInput) + total;
            }
           }
        //    if (total === 0) {
        //     total = 0;
        //     for(let i=0; i<data.length; i++) {
        //         total = parseInt(data[i].freeStock) + total;
                
        //        }
        //    }
           return total;
        }
        function getTotalDigffrence(total, sageStock) {
            return parseInt(total) - parseInt(sageStock);
        }

        // function sortData(sortType, sortBy) {
        //     setOrderByKey(sortBy);
        //     setOrderType(sortType);
        //     // setIsLoading(true);
        //     getStockLocationsData(currentPageNo, searchText, sortType, sortBy);
        // }

        const handleSort = (key) => {
            setIsLoading(true);
            if (sortConfig.key === key && sortConfig.direction === 'asc') {
                setSortConfig({ key, direction: 'desc' });
                getStockLocationsData(currentPageNo, searchText, "desc", key);
            } else {
                setSortConfig({ key, direction: 'asc' });
                getStockLocationsData(currentPageNo, searchText, "asc", key);
            }
        }

        const handleDeleterecord = async() => {
            try {
                const result = await deleteStocks(recordDeleteArray);
                setCheckedItems({});
                setRecordDeleteArray([]);
                setIsLoading(true);
                getStockLocationsData(currentPageNo, searchText, sortConfig.direction, sortConfig.key);
            } catch (error) {
                throw error;
            }
        }

       async function exportToSage() {
        setExportDataLoader(true);
        try {
            const result = await exportDataToSage();
            toast.success("Data exported to sage successfully", {
                position: "bottom-right",
                autoClose: 2000, 
              });
              setExportDataLoader(false);
              getStockLocationsData(currentPageNo, searchText, sortConfig.direction, sortConfig.key);
        } catch (error) {
            toast.error("Something went wrong", {
                position: "bottom-right",
                autoClose: 2000, 
              });
              setExportDataLoader(false);
            throw error;
        }
        }

        const fixedColumnStyle1 = {
            width: `${columnWidths.column1.fixedColumnWidth}%`,
            left: `${columnWidths.column1.left}px`,
        };
        const fixedColumnStyle2 = {
            width: `${columnWidths.column2.fixedColumnWidth}%`,
            left: `${columnWidths.column2.left}%`,
        };
        const fixedColumnStyle3 = {
            width: `${columnWidths.column3.fixedColumnWidth}%`,
            left: `${columnWidths.column3.left}%`,
        };
        const fixedColumnStyle4 = {
            width: `${columnWidths.column4.fixedColumnWidth}%`,
            left: `${columnWidths.column4.left}%`,
        };
        const fixedColumnStyleLast = {
            width: `${columnWidths.columnLast.fixedColumnWidth}%`,
            right: `${columnWidths.columnLast.right}`,
        };
        
          const scrollableColumnStyle = {
            width: `${columnWidths.scrollableColumnWidth.scrollableColumnWidth}%`,
            marginLeft: `${columnWidths.scrollableColumnWidth.left}%`
            // overflowX: 'auto',
          };
          function clearSearch() {
            setSearchText("");
            setCurrentPageNo(1);
            setIsLoading(true);
            setSearchLoad(true);
            getStockLocationsData(1, "", sortConfig.direction, sortConfig.key);
          }

          const handleKeypress = e => {
          if (e.keyCode === 13) {
            handleSearch();
          }
        };

    return (
        <div className='listing-main'>
             <div className="d-flex py-3 search-box">
                <div className="search-main">
                    {/* <input className="form-control me-2" placeholder="Search" value={searchText} onChange={e=> {handleSearch(e.target.value)}}/>
                    {searchText !=="" &&
                        <span className="search-icon" onClick={clearSearch}>x</span>
                    } */}
                    <div class="input-group">                   
                    <input className="form-control" placeholder="Search" value={searchText} onKeyDown={e=> {handleKeypress(e)}} onChange={e=> {setSearchText(e.target.value)}} />
                    {searchText !=="" &&
                        <span className="search-icon" onClick={clearSearch}>x</span>
                    }
                    <button class="btn btn-outline-secondary" type="button" onClick={handleSearch}>Search</button>
                    </div>
                </div>
                <span role="button" className='select-all d-flex align-items-center'>
                    <span onClick={handleSelectAllChange}>Select All</span>
                    {recordDeleteArray.length > 0 &&
                        <span className="ms-3" onClick={handleDeleterecord}><img src={deleteIcon} alt="delete" style={{width: "20px", cursor: "pointer"}} /></span>
                    }
                </span>
                {(authCtx.user.email === "gill.reid@capitalpowerclean.co.uk" || authCtx.user.email === "admin@capitalpowerclean.com" || authCtx.user.email === "abdullahmoazzam@gmail.com") &&
                    <> 
                        {!exportDataLoader &&
                            <button className="btn export-btn" onClick={exportToSage}>Export to Sage</button>
                        }
                    </>
                }
                {exportDataLoader &&
                    <>
                    <button className="btn export-btn" style={{background: "#ffffff"}}>
                        <img src={asset_loader} alt="loader" style={{width: "30px"}} />
                    </button>
                    </>
                }
            </div>
             {isLoading ?(
                <div className="my-5">
                    <ShimmerTable row={8} col={6} />
                </div>
            ):(
            <>
            <div className="mt-2 wrapper" style={scrollableColumnStyle}>
                <table className="table table-main">
                    <tr>
                    <th scope="col" className="first" style={fixedColumnStyle1}>
                            <div className="thead-main " onClick={()=>{handleSort('ProductCode')}}>
                                <span>Item Code </span>
                                {sortConfig.key === 'ProductCode' ? (sortConfig.direction === 'asc' ? <span className="sort-arrow">⬆</span> : <span className="sort-arrow">⬇</span>) : ''}
                            </div>
                        </th>
                        <th scope="col" className="second" style={fixedColumnStyle2}>
                            <div className="thead-main" onClick={()=>{handleSort('PartNo')}}>
                                <span>Part No </span>
                                {sortConfig.key === 'PartNo' ? (sortConfig.direction === 'asc' ? <span className="sort-arrow">⬆</span> : <span className="sort-arrow">⬇</span>) : ''}
                            </div>
                        </th>
                        <th scope="col" className="third" style={fixedColumnStyle3}>
                            <div className="thead-main" onClick={()=>{handleSort('Description')}}>
                                <span>Description </span>
                                {sortConfig.key === 'Description' ? (sortConfig.direction === 'asc' ? <span className="sort-arrow">⬆</span> : <span className="sort-arrow">⬇</span>) : ''}
                            </div>
                        </th>
                        <th scope="col" className="fourth text-center" style={fixedColumnStyle4}>
                            <div className="thead-main d-flex justify-content-center" onClick={()=>{handleSort('FreeStock')}}>
                                <span>Sage Stock </span>
                                {sortConfig.key === 'FreeStock' ? (sortConfig.direction === 'asc' ? <span className="sort-arrow">⬆</span> : <span className="sort-arrow">⬇</span>) : ''}
                            </div>
                        </th>
                        <th colSpan={4} className="position-relative location-box rest">
                        <div className="location-drop" style={{width: `${columnWidths.locationDropWidth}`}}>
                            <div className="dropdown">
                                <div className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Location
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" onClick={handleDropdownClick}>
                                {locationsData.map((data, index) =>
                                    <li key={index}>
                                    <div className="dropdown-item">
                                        <div className="checkboxes__item">
                                            <label className="checkbox cc-box">
                                                <input type="checkbox" checked={checkedStates[index]}   onChange={(event) => {handleChangeForLocationDrop(index, event.target, false, data.locationId)}}/>
                                                <div className="checkbox__checkmark"></div>
                                                <div className="checkbox__body">{data.locationName}</div>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                                )}  
                                </ul>
                            </div>
                        </div>
                            <div className='d-flex justify-content-around locations-headings p-0'>
                                {stockTakeData[0]?.locationsWithTakes.map((locaData, index) =>
                                    <>
                                    {locaData.isShow && 
                                        <>
                                    <span className="me-2 loc-values" key={index}>
                                        <div className="text-danger" style={{cursor: 'pointer'}} onClick={() => handleChangeForLocationDrop(index, false, true, locaData.locationId)}>X</div>
                                        {locaData.locationName}
                                    </span>
                                    </>
                                    }
                                    </>
                                )} 
                            </div>
                        </th>
                        <th className="last" style={fixedColumnStyleLast}>Location Total</th>
                        </tr>
                    {stockTakeData.map((data, index) =>
                        <tr key={index}>
                            <td className="first" style={fixedColumnStyle1}>
                            <div className="checkboxes__item">
                                <label className="checkbox cc-box">
                                    <input 
                                        type="checkbox"  
                                        checked={checkedItems[data.stockId] || false}
                                        onChange={() => handleItemChange(data.stockId)}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                    <div >{data.productCode}</div>
                                </label>
                            </div>
                                
                                </td>
                            <td className="second" style={fixedColumnStyle2}>{data.partNo === "" ? "---": data.partNo}</td>
                            <td className="third" style={fixedColumnStyle3}>{data.description === "---"? "null": data.description }</td>
                            <td className="fourth text-center" style={fixedColumnStyle4}>{data.freeStock}</td>
                            <td colSpan={4} className="location-box rest">
                                    <div className='locations-headings d-flex justify-content-around'>
                                    {data.locationsWithTakes.map((locaData, childIndex) =>
                                        <>
                                        {locaData.isShow && 
                                        <>
                                        <div className="d-flex justify-content-around align-items-center loc-values"> <span className="me-1">{locaData.freeStock}</span>
                                            <input type="number" className={`form-control location-input ${parseInt(locaData.currentStock) === parseInt(locaData.freeStock) ? 'text-success' : 'text-danger'}`} value={locaData.currentStock} onChange={(event)=>{handleChange(event.target.value, locaData, data.stockId, index, childIndex)}}  onFocus={() => handleFocus('', locaData, data.stockId, index, childIndex)} onBlur={(event) => onFocusOut(event.target.value, locaData, data.stockId, index, childIndex)} />
                                        </div>
                                            </>
                                        }                                        
                                        </>
                                    )}
                                </div>
                                </td>
                                <td className="last" style={fixedColumnStyleLast}>
                                {!data.isLoader &&
                                    // <div className={`${parseInt(data.totalCount) === parseInt(data.freeStock) ? 'text-success' : 'text-danger'}`}>
                                    // {data.totalCount} ({data.diffrenceFromTotal})
                                    // </div>
                                    <div>
                                        {data.totalCount} 
                                        {/* ({Math.abs(data.diffrenceFromTotal)}) */}
                                        {data.diffrenceFromTotal < 0 ? (
                                            <span className="negative"><span className={`${parseInt(data.totalCount) === parseInt(data.freeStock) ? 'text-success' : 'text-danger'}`}>(−{Math.abs(data.diffrenceFromTotal)})</span></span> // Using the mathematical minus sign (−) instead of hyphen (-)
                                        ) : (
                                            // <span className="positive">+{data.diffrenceFromTotal}</span>
                                            <span className="positive">
                                                <span className={`${parseInt(data.totalCount) === parseInt(data.freeStock) ? 'text-success' : 'text-danger'}`}>
                                                    (
                                                        {data.diffrenceFromTotal > 0 &&
                                                        <>
                                                            +
                                                        </>
                                                        }
                                                        {Math.abs(data.diffrenceFromTotal)})
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                }
                                {data.isLoader &&
                                <>
                                    <img src={asset_loader} alt="loader" style={{width: "40px"}} />
                                </>
                                }
                            </td>
                        </tr>
                        )}
                </table>
            </div>
            </>
            )}
            <div className="mt-3 p-3">
                {!searchLoad &&
                <div className="d-flex align-items-center">
                    <Pagination itemsPerPage={10} items={stockTakeData} totalPages={pageCount}  onNavigation={paginatedData}/>
                    {!isLoading && 
                        <h6 className="mb-3">Total Stock Items: {totalUniqueStocks} </h6>
                    }
                </div>
                }
            </div>
        </div>
    )
}