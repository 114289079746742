class GlobalService {
    // baseUrl = "https://cpcapi.pixel-fusion.com"; // for Dev
     baseUrl = "https://tronapi.capitalpowerclean.co.uk"; //for Live
    login = `${this.baseUrl}/api/authenticate/login`;
    users = `${this.baseUrl}/api/authenticate/get`;
    register = `${this.baseUrl}/api/authenticate/register-user`;
    deleteUser = `${this.baseUrl}/api/authenticate/delete-user`;
    // -----------stocks---------
    locations = `${this.baseUrl}/api/locations`;
    allLocations = `${this.baseUrl}/api/locations/getall`;
    stockLocations = `${this.baseUrl}/api/stocks/search`;
    stockTakes = `${this.baseUrl}/api/stockTakes`;
    deleteStocks = `${this.baseUrl}/api/stocks/[ids]`;
    exportSageData = `${this.baseUrl}/api/stocks/exportData`;
    // -----------reports---------
    reports = `${this.baseUrl}/api/reports`;
    saveReport = `${this.baseUrl}/api/reports/download`;

 
}
const globalData = new GlobalService();
export default globalData;
