import axios from 'axios';
import { toast } from 'react-toastify';

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const instance = axios.create({
  baseURL: 'https://jsonplaceholder.typicode.com/'
});

instance.interceptors.request.use(
  config => {
    console.log('Request was sent to ' + config.url);
    if (config.url !== global.baseUrl + '/api/authenticate/login') {
        config.headers['Authorization'] = `Bearer  ${localStorage.getItem('token')}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status===401) {
            localStorage.clear();
            window.location.href = '/login';
        }
        if (error.response.status===400) {
          toast.error("bad request", {
            position: "bottom-right",
            autoClose: 2000, 
          });
      }
      if (error.response.status === 500) {
        toast.error(error.response.data.message, {
          position: "bottom-right",
          autoClose: 2000, 
        });
      }
    return Promise.reject(error);
  }
);

export default instance;