import React, { useContext, useEffect, useState } from "react";

import { ShimmerText } from "react-shimmer-effects";

import { getAllUsers, saveNewUser, deleteUser } from "../../services/dataService";


import deleteIcon from '../../assets/delete.svg';




import './userManagement.scss';

export default function UserManagment() {
  const [users, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedUserPassword, setSelectedUserPassword] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  

    useEffect(()=> {
        getUsersData();
    }, [])
    const getUsersData = async () => {
        try {
            setIsLoading(true);
            const result = await getAllUsers();
            setUsersData(result);
            setIsLoading(false);
            clearData();
        } catch (error) {
            setIsLoading(false);
        }
    };
    const saveNewUserReq = async () => {
        try {
            const result = await saveNewUser(selectedUser, selectedUserName, selectedUserPassword);
            getUsersData();
        } catch (error) {
        }
    };

    // const handleSelectLocation = (user) => {
    //     setSelectedUser(user.email);
    //     setSelectedUserName(user.userName);
    //     setShowDelete(true);
    //   };

    const handleDeleteUser = async(userName) => {
        try {
            // const foundUser = users.find(user => user.email === selectedUser);
            const result = await deleteUser(userName);
            getUsersData();
        } catch (error) {
        }
    };
    const clearData = () => {
        setSelectedUser('');
        setSelectedUserName('');
        setSelectedUserPassword('');
        setShowDelete(false);
    }
    const handleChange = (field, value) => {
        if (field === 'email')setSelectedUser(value);
        if (field === 'username')setSelectedUserName(value);
        if (field === 'password')setSelectedUserPassword(value);
        setShowDelete(false);
    }
    return (
        <div className="location-main">
            <div className="locations-list">
                <h5 className="heading">Users</h5>
                {isLoading && 
                    <ShimmerText line={5} gap={10} />
                }
                {!isLoading && users.length === 0 && 
                    <div>No Data Found</div>
                }
                {users.map((userData, index) => (
                <div className="list-content d-flex justify-content-between align-items-center" key={index}>
                    {userData.email}
                    <img style={{width:'20px'}} src={deleteIcon} alt="delete" onClick={() => {handleDeleteUser(userData.userName)}}/>
                </div>
                ))}
                {/* <div className="mt-3 d-flex justify-content-around">
                    <Pagination itemsPerPage={10} items={users} totalPages={pageCount}  onNavigation={paginatedData}/>
                </div> */}
            </div>

            <div className="location-editor">
                <div className="row">
                    <div className="col-4 action-buttons">
                        <h5 className="heading">Add User</h5>
                        <div className="mt-3">
                        <label>Email</label>
                            <input 
                            type="text" 
                            placeholder="email"
                            value={selectedUser}
                            className="form-control"
                            onChange={(e) => handleChange('email', e.target.value)} 
                            />
                            <label>Username</label>
                            <input 
                            type="text" 
                            placeholder="username"
                            value={selectedUserName}
                            className="form-control"
                            onChange={(e) => handleChange('username', e.target.value)} 
                            />
                            <label>Password 
                                <span style={{color: '#eb3b3b', fontSize:'12px', display: 'block'}}>(The password must contain a capital letter, and a number)</span>
                            </label>
                            <input 
                            type="text" 
                            placeholder="password"
                            value={selectedUserPassword}
                            className="form-control"
                            onChange={(e) => handleChange('password', e.target.value)} 
                            />
                        </div>
                        {/* {showDelete && 
                            <div className="action-del" onClick={handleDeleteUser}>Delete</div>
                        } */}
                        <div className="text-end">
                        <button type="button" className="btn btn-sm" onClick={clearData}>Cancel</button>
                        <button type="button" className="btn btn-sm" onClick={saveNewUserReq} >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}